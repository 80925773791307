import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section1.module.scss'
import { Fade } from 'react-reveal'

const Section1 = props => {
  const { className, children, id, title } = props
  return (<div id={id} className={[className, styles.container, 'container-fluid'].join(' ')}>
    <div className={['row text-center', styles.centered].join(' ')}>
      <div className={[styles.title, 'col-md-12'].join(' ')}>
        <Fade>
          {title}
        </Fade>
      </div>
    </div>
    <div className='container'>
      <div className='row'>
        {children}
      </div>
    </div>
  </div>)
}

Section1.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string,
  data: PropTypes.object,
  language: PropTypes.string
}

export default Section1
