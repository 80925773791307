import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '@layouts/Layout'
import { Section1 } from '@templates/features'
import {
  activeDirectorySupport,
  andMuchMore,
  applicationPublishing,
  citrixLikeWebApplicationsPortal,
  easyToUseAdministrationTool,
  gatewayPortal,
  html5ClientForComputersTabletsMobiles,
  loadBalancing,
  openOnClient,
  portableClientGenerator,
  remoteDesktopAccess,
  seamlessAndRemoteapp,
  securewebTunnel,
  universalPrinter,
  webAccess,
  webCredentials,
  webmasterToolkit
} from '@images'
import HeadShake from 'react-reveal/HeadShake'
import Reveal from 'react-reveal/Reveal'

const Features = props => {
  const { formatMessage } = useIntl()
  const { pageContext } = props
  const { languageName } = pageContext

  const SubSection = ({ src, children, alt }) => {
    return (<div>
      <div className="text-center">
        <HeadShake>
          <img src={src} alt={alt}/>
        </HeadShake>
      </div>
      <div className="txt-3 txt-primary mb-1 mt-3">
        {alt}
      </div>
      <Reveal effect="fadeInUp">
        {children}
      </Reveal>
    </div>)
  }

  return <Layout languageName={languageName} pageContext={pageContext}>
    <div className='row'>
      <Section1 title={'Standard features'}>
        <div className="col-md-4">
          <SubSection src={remoteDesktopAccess} alt="Remote Desktop Access">
            <p>TSplus enables any of your Windows from Windows 7 to 10 Pro and Server 2008 to 2019 to act as a Citrix or a Terminal Server.</p>
            <p>TSplus supports concurrent Remote Desktop (RDS) connections, printer mapping, disk mapping, port com mapping, bi-directional sound, remoteFX, dual screen. Any RDP compatible client can be used to connect on a TSplus System.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={applicationPublishing} alt="Application Publishing">
            <p>Publish applications for a selection of users and/or groups and they will have access only to their applications through TSplus Remote Taskbar and TSplus Floating Panel (no Windows Start menu, no Windows desktop).</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={seamlessAndRemoteapp} alt="Seamless and RemoteApp">
            <p>TSplus allows publishing of a single application for a given user. The application will be displayed on the local user desktop, not on a remote desktop standard session.</p>
            <p>It will look as if the application was running on his computer (like Citrix or MS RDS applications).</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={universalPrinter} alt="Universal Printer">
            <p>TSplus Universal Printer enables you to print from any location, without having to install any specific printer driver.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={easyToUseAdministrationTool} alt="Easy-to-use administration tool">
            <p>TSplus Administrator Tool is a unique application that centralizes all of the server's configuration tools, including Windows features, in one easy-to-use interface.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={activeDirectorySupport} alt="Active Directory Support">
            <p>Allows administrators to easily control access to Windows apps based on Active Directory user groups.</p>
            <p>Also works with local groups and users (Workgroup).</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={portableClientGenerator} alt="Portable Client Generator">
            <p>TSplus enables you to generate a portable remote connection client that integrates all the required settings.</p>
            <p>The final user does not have to remember his customized parameters anymore; a simple click on the generated client program and he is connected to his remote server.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={openOnClient} alt="Open on Client">
            <p>When working remotely on your server, TSplus allows opening Word documents, Excel workbooks and other files automatically on the user side. There is no need to install Office on the host server.</p>
          </SubSection>
        </div>
      </Section1>
      <Section1 title={'Web features'}>
        <div className="col-md-4">
          <SubSection src={webAccess} alt="Web Access">
            <p>TSplus built-in HTTP Web Server enables you to connect from any web browser: Internet Explorer, Firefox, Chrome, Opera...</p>
            <p>The provided html pages includes Windows, Linux and MAC web access clients, as well as an HTML5 client for any web browser.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={html5ClientForComputersTabletsMobiles} alt="HTML5 client for computers, tablets &amp; mobiles">
            <p>TSplus built-in HTML5 client provides a browser access to Windows applications and user desktops from all types of end-user devices, without having to install anything on the device (no plugin or app required).</p>
            <p>TSplus HTML5 connections look, work and perform like a native RDP connection; with a fast file transfer and even with sound and clipboard support.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={securewebTunnel} alt="SecureWeb Tunnel">
            <p>TSplus built-in HTTPS Web Server and SSH server enables you to connect fully securely from any web browser with SSH Tunneling and port forwarding over HTTP and HTTPS.</p>
            <p>Without complex VPN, all network communications are end-to-end encrypted. The tunnel is established over the port 80 or 443 and the port 3389 is not required nor requested anymore.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={citrixLikeWebApplicationsPortal} alt="Citrix-like Web Applications Portal">
            <p>Publish applications through the web and your users will be able to access their applications directly from the Internet, simply by clicking on the application icon on the TSplus Web Portal.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={webCredentials} alt="Web Credentials">
            <p>Using TSplus Web Credentials, your users can connect with a simple PIN code or their e-mail address, without having to enter (or to know) the matching Windows credentials.</p>
            <p>It can be very useful if you publish a business application with its own authentication scheme, or if you want to ease the remote access from a tablet inside your corporate network.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={webmasterToolkit} alt="Customizable web portal">
            <p>With TSplus Webmaster Toolkit it is really easy to customize Web Access pages with your company colors, name and pictures. In one click and without any web design skills your users entry point looks corporate!</p>
          </SubSection >
        </div>
      </Section1>
      <Section1 title={'Farm features'}>
        <div className="col-md-4">
          <SubSection src={gatewayPortal} alt="Gateway Portal">
            <p>TSplus Gateway Portal enables access to multiple servers with web control of the user credentials and Single-Sign on (SSO). It is also possible to assign specific servers to specific users or groups, in order to control servers access.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={loadBalancing} alt="Load Balancing">
            <p>TSplus load balancing feature can split the load between multiple servers of your cluster. It also allows falling back to failover servers in case of a production incident. Benefit from an unlimited number of servers within one farm.</p>
          </SubSection>
        </div>
        <div className="col-md-4">
          <SubSection src={andMuchMore} alt="And much more...">
            <ul className="pl-3">
              <li>Lowest-cost Citrix/TS product available on XP, VISTA, W7, W8, W10 Pro and 2003 to 2019 systems (32 and 64 bits)</li>
              <li>No requirement for Terminal Service CALs. No need for Terminal Service (Remote Desktop Service) license manager</li>
            </ul>
          </SubSection>
        </div>
      </Section1>
    </div>

  </Layout>
}

Features.propTypes = {
  pageContext: PropTypes.any
}

export default Features
